import gql from "graphql-tag";
import {Query} from "@deity/falcon-ecommerce-uikit";

export const GET_CATEGORY_CHILD = gql`
  query CategoryChild($categoryId: String!, $amount: Int) {
    category(id: $categoryId) {
      name
      description
      urlPath
      products(pagination: { perPage: $amount, page: 1}) {
        items {
          id
          brandName
          new
          shortDescription
          promotionalBadge
          name
          price {
            regular
            special
            minTier
          }
          thumbnail
          urlPath
          attributes {
            attributeCode
            value
          }
          configurableOptions {
            values {
              label
              price {
                regular
                special
                minTier
                suggestedRetail
              }
            }
          }
        }
      }
    }
  }
`;

export class CategoryChildQuery extends Query {
  static defaultProps = {
    query: GET_CATEGORY_CHILD,
  };
}
