import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { Redirect } from "react-router-dom";

import {
  SearchConsumer,
  SortOrdersProvider,
  getFiltersData,
  Loader,
  FilterOperator
} from "@deity/falcon-ecommerce-uikit";

import {
  ProductsList,
  PageHeader,
  Headband,
  CardBrand,
  Filters,
  FooterCategory,
  SortDropdown,
  ProductListHeader,
  SubCategoriesHeader,
  BrandHeader
} from "benlux-ecommerce-ui";

import { brand } from "../../../data/dataBrand";
import { CategoryProductsListQuery } from "../../../queries/CategoryProductsListQuery";
import { MenuQuery } from "../../../queries/MenuQuery";
import { CategoryHeaderQuery } from "../../../queries/CategoryHeaderQuery";
import { CategoryChildQuery } from "../../../queries/CategoryChildQuery";
import withProductListingConfig from "../../../components/withProductListingConfig";
import NotFound from "../../../components/NotFound";
import { KilibaCategoryViewTracker } from "../../../components/KilibaTracker/KilibaCategoryViewTracker";

const copy = item => item && JSON.parse(JSON.stringify(item));

const FACETS = [
  {
    attribute: "brand",
    title: "Nos sélections par marque"
  },
  {
    attribute: "lipstick_finish",
    title: "Nos rouges à lèvres par fini"
  },
  {
    attribute: "deodorant_type",
    title: "Nos déodorants par format"
  },
  {
    attribute: "volume",
    title: "Nos sélections par format"
  },
  {
    attribute: "makeup_foundation_texture",
    title: "Nos fonds de teint par texture"
  },
  {
    attribute: "olfactory_family",
    title: "Nos parfums par famille olfactive"
  }
];

const CategoryPage = ({
  id,
  location,
  history,
  productListingConfig,
  staticContext
}) => {
  const query = new URLSearchParams(location.search);
  const [page, setPage] = useState(null);

  // Redirect ?page=1 to base url
  if (query && query.get("page")) {
    if (query.get("page") === "1") {
      staticContext.statusCode = 301;
      return (
        <Redirect
          to={{
            pathname: location.pathname
          }}
        />
      );
    }
  }

  if (!page) {
    if (query && query.get("page")) {
      try {
        setPage(parseInt(query.get("page")));
      } catch (err) {}
    } else {
      setPage(1);
    }
  }

  useEffect(() => {
    if (query) {
      try {
        if (query.get("page")) {
          setPage(parseInt(query.get("page")));
        } else {
          setPage(1);
        }

        if (window) {
          window.scroll(0, 0);
        }
      } catch (err) {}
    }
  }, [query]);

  const findCategory = (menu, id) => {
    for (let item of menu) {
      if (item.id === id) return item;

      if (item.children) {
        let desiredNode = findCategory(item.children, id);
        if (desiredNode) return desiredNode;
      }
    }
    return false;
  };

  return (
    <MenuQuery>
      {({ menu }) => {
        let isTopCategory = false;
        const allProductsCategory = menu.find(c => c.urlPath === "/categories");
        let topCategory = allProductsCategory.children.find(c => c.id === id);
        if (topCategory) isTopCategory = true;
        const categoryFromMenu = findCategory(menu, id);

        return (
          <CategoryHeaderQuery
            variables={{
              categoryId: id,
              path: location.pathname
            }}
          >
            {({ category, loading, ...data }) => {
              if (loading) {
                return <p>Chargement principal...</p>;
              }

              if (!category.isActive) {
                if (staticContext) {
                  staticContext.statusCode = 404;
                }

                return <NotFound />;
              }

              const { name, image, seo, urlPath } = category;
              const title = seo.title || name;
              const description = seo.description || category.description || "";
              const h1 = seo.h1 || name || "";
              const canonical = seo.canonical ? `/${seo.canonical}` : urlPath;

              const isBrandSubcategory =
                category.breadcrumbs &&
                category.breadcrumbs.find(b => b.urlPath === "/marques") &&
                category.breadcrumbs.length >= 2;

              if (category.breadcrumbs.length === 0) {
                topCategory = category;
                isTopCategory = true;
              }

              if (
                isTopCategory &&
                category.children &&
                category.children.filter(c => c.name !== "Default Category")
                  .length === 0
              ) {
                isTopCategory = false;
              }

              const brandPath = isBrandSubcategory
                ? category.breadcrumbs[1].urlPath
                : null;

              const footerLinks = [];

              if (topCategory) {
                topCategory.children
                  .filter(
                    c =>
                      c.id !== id &&
                      c.name !== "Nouveautés" &&
                      c.name !== "Meilleures ventes" &&
                      c.name !== "Default Category"
                  )
                  .forEach(topCategoryChild => {
                    footerLinks.push({
                      title: `${topCategory.name} > ${topCategoryChild.name}`,
                      titleUrlPath: topCategoryChild.urlPath,
                      items: topCategoryChild.children
                        ? topCategoryChild.children.filter(
                            c =>
                              !c.isSmart &&
                              c.name !== "Nouveautés" &&
                              c.name !== "Meilleures ventes" &&
                              c.name !== "Default Category"
                          )
                        : []
                    });
                  });
              }

              FACETS.forEach(facetCategory => {
                const facetCategories = category.children.filter(
                  c => c.isFacet && c.facetAttribute === facetCategory.attribute
                );
                if (facetCategories.length > 0) {
                  footerLinks.push({
                    title: facetCategory.title,
                    items: facetCategories
                  });
                }
              });

              const othersFacets = category.children.filter(
                c => c.isFacet && !c.facetAttribute
              );
              othersFacets.forEach(facetCategory => {
                footerLinks.push({
                  title: facetCategory.name,
                  titleUrlPath: facetCategory.urlPath,
                  items: []
                });
              });

              footerLinks.push({
                title: "Nos autres produits",
                items: allProductsCategory.children
              });

              return (
                <>
                  <Helmet>
                    {/* TODO: dynamic base path */}
                    <title>{title}</title>
                    <meta name="description" content={description} />
                    <link
                      rel="canonical"
                      href={
                        "https://www.benlux.fr" +
                        canonical +
                        (page > 1 ? "?page=" + page : "")
                      }
                    />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content={title + " ‣ BENLUX"} />
                    {image && <meta property="og:image" content={image} />}
                    <meta property="og:description" content={description} />
                    <meta
                      property="og:url"
                      content={"https://www.benlux.fr" + urlPath}
                    />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta property="twitter:domain" content="benlux.fr" />
                    <meta
                      property="twitter:url"
                      content={"https://www.benlux.fr" + urlPath}
                    />
                    <meta name="twitter:title" content={title + " ‣ BENLUX"} />
                    <meta name="twitter:description" content={description} />
                    {image && <meta name="twitter:image" content={image} />}
                    <meta name="page-loaded" content="true" />
                  </Helmet>
                  <KilibaCategoryViewTracker categoryId={id} />
                  {isBrandSubcategory && brandPath === "/dior" ? (
                    <BrandHeader brand={category} brandPath={brandPath} />
                  ) : (
                    <PageHeader
                      description={category.description}
                      image={image}
                      title={h1}
                      categories={
                        categoryFromMenu ? categoryFromMenu.children : []
                      }
                    />
                  )}
                  {isTopCategory &&
                  ["/soldes", "/black-friday", "/fete-des-peres"].indexOf(
                    urlPath
                  ) === -1 ? (
                    <>
                      {category.children
                        .filter(c => !c.isFacet)
                        .map((child, index) => (
                          <div key={index}>
                            {child.name !== "Nouveautés" &&
                              child.name !== "Meilleures ventes" && (
                                <CategoryChildQuery
                                  variables={{
                                    categoryId: child.id,
                                    amount: 10
                                  }}
                                  passLoading
                                >
                                  {({ category, loading }) => {
                                    return (
                                      <SubCategoriesHeader
                                        categoryName={name}
                                        childMenu={child}
                                        child={category}
                                        loading={loading}
                                        loader={<Loader />}
                                        imgLazyLoading={index > 0}
                                      />
                                    );
                                  }}
                                </CategoryChildQuery>
                              )}
                          </div>
                        ))}
                      <Headband
                        showButton={true}
                        title={"Les marques trending"}
                        textButton={"Voir toutes les marques"}
                        urlPath={"../marques"}
                        description="Pour connaître les nouveautés de vos marques favorites avant tout le monde,
                                        suivez les marques que vous préférez.
                                        Débloquez ainsi une expérience personnalisée sur le site BENLUX."
                      />
                      <CardBrand brand={brand} />
                      <FooterCategory category={category} links={footerLinks} />
                    </>
                  ) : (
                    <SearchConsumer>
                      {({ state, setFilter, removeFilters, removeFilter }) => (
                        <CategoryProductsListQuery
                          variables={{
                            pagination: {
                              perPage: productListingConfig.perPage,
                              page: page
                            },
                            categoryId: id,
                            sort: state.sort,
                            filters: copy(state.filters),
                            path: location.pathname
                          }}
                        >
                          {({
                            category,
                            fetchMore,
                            networkStatus,
                            loading,
                            ...data
                          }) => {
                            if (!category && loading) {
                              return <Loader />;
                            }
                            const { products, breadcrumbs } = category;
                            //category.children = [];
                            const {
                              pagination,
                              items,
                              aggregations,
                              priceLimits
                            } = products;
                            const filtersData = getFiltersData(
                              state.filters,
                              aggregations
                            );

                            let topCategoryId = null;
                            if (breadcrumbs.length > 0) {
                              topCategoryId =
                                breadcrumbs.length > 1
                                  ? breadcrumbs[1].id
                                  : breadcrumbs[0].id;
                            }

                            const topCategory = findCategory(
                              menu,
                              topCategoryId
                            );
                            let parentCategory = topCategory;

                            if (breadcrumbs.length > 2) {
                              parentCategory = findCategory(
                                menu,
                                breadcrumbs[2].id
                              );
                            }

                            const footerLinks = [];

                            footerLinks.push({
                              title:
                                "Nos catégories " + category.name.toLowerCase(),
                              items: category.children.filter(
                                c =>
                                  !c.isSmart &&
                                  c.name !== "Nouveautés" &&
                                  c.name !== "Meilleures ventes" &&
                                  c.name !== "Default Category"
                              )
                            });

                            if (
                              parentCategory &&
                              parentCategory.id !== topCategoryId
                            ) {
                              footerLinks.push({
                                title:
                                  "Nos autres catégories " +
                                  parentCategory.name.toLowerCase(),
                                items: parentCategory.children.filter(
                                  c =>
                                    c.id !== id &&
                                    !c.isSmart &&
                                    c.name !== "Nouveautés" &&
                                    c.name !== "Meilleures ventes" &&
                                    c.name !== "Default Category"
                                )
                              });
                            }

                            if (topCategory) {
                              topCategory.children
                                .filter(
                                  c =>
                                    c.id !== id &&
                                    c.id !== parentCategory.id &&
                                    c.name !== "Nouveautés" &&
                                    c.name !== "Meilleures ventes" &&
                                    c.name !== "Default Category"
                                )
                                .forEach(topCategoryChild => {
                                  footerLinks.push({
                                    title: `${topCategory.name} > ${topCategoryChild.name}`,
                                    titleUrlPath: topCategoryChild.urlPath,
                                    items: topCategoryChild.children.filter(
                                      c =>
                                        !c.isSmart &&
                                        c.name !== "Nouveautés" &&
                                        c.name !== "Meilleures ventes" &&
                                        c.name !== "Default Category"
                                    )
                                  });
                                });
                            }

                            FACETS.forEach(facetCategory => {
                              const facetCategories = category.children.filter(
                                c =>
                                  c.isFacet &&
                                  c.facetAttribute === facetCategory.attribute
                              );
                              if (facetCategories.length > 0) {
                                footerLinks.push({
                                  title: facetCategory.title,
                                  items: facetCategories
                                });
                              }
                            });

                            const othersFacets = category.children.filter(
                              c => c.isFacet && !c.facetAttribute
                            );
                            othersFacets.forEach(facetCategory => {
                              footerLinks.push({
                                title: facetCategory.name,
                                titleUrlPath: facetCategory.urlPath,
                                items: []
                              });
                            });

                            footerLinks.push({
                              title: "Nos autres produits",
                              items: allProductsCategory.children
                            });

                            return (
                              <>
                                <Helmet>
                                  {pagination.currentPage > 1 && (
                                    <link
                                      rel={"prev"}
                                      href={
                                        location.pathname +
                                        (pagination.currentPage > 2
                                          ? "?page=" +
                                            (pagination.currentPage - 1)
                                          : "")
                                      }
                                    />
                                  )}
                                  {pagination.currentPage <
                                    pagination.nextPage && (
                                    <link
                                      rel={"next"}
                                      href={
                                        location.pathname +
                                        "?page=" +
                                        pagination.nextPage
                                      }
                                    />
                                  )}
                                </Helmet>
                                {/* TODO: new subcategories header
                                <div
                                  style={{
                                    display: "flex",
                                    margin: "0 auto",
                                    maxWidth: "1200px",
                                    position: "relative",
                                    top: "-48px",
                                    gap: "48px"
                                  }}
                                >
                                  {[
                                    {
                                      label: "Offre Spéciales",
                                      image: image1,
                                      url: "./offres-speciales"
                                    },
                                    {
                                      label: "Promotions Parfums",
                                      image: image2,
                                      url: "./soldes-parfums"
                                    },
                                    {
                                      label: "Promotions Crèmes et Soins",
                                      image: image3,
                                      url: "./soldes-soins"
                                    },
                                    {
                                      label: "Offre de bienvenue",
                                      image: image4,
                                      url: "./newsletter"
                                    }
                                  ].map((x, i) => (
                                    <a
                                      href={x.url}
                                      key={i}
                                      style={{
                                        textAlign: "center",
                                        borderRadius: "0px",
                                        overflow: "hidden",
                                        textDecoration: "none",
                                        color: "#000",
                                        background: "#FAFAFA"
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "260px",
                                          height: "160px",
                                          borderRadius: "0px",
                                          backgroundImage:
                                            "url(" + x.image + ")",
                                          backgroundSize: "cover",
                                          cursor: "pointer"
                                        }}
                                      />
                                      <span
                                        style={{
                                          display: "block",
                                          lineHeight: "16px",
                                          margin: "16px 0",
                                          fontFamily: "Inter, sans-serif",
                                          fontSize: "14px",
                                          fontWeight: "400"
                                        }}
                                      >
                                        {x.label}
                                      </span>
                                    </a>
                                  ))}
                                </div>
                                */}
                                <ProductListHeader
                                  breadcrumbs={category.breadcrumbs}
                                  categoryName={category.name}
                                  clearFilter={removeFilters}
                                >
                                  {items.length > 1 ? (
                                    <SortOrdersProvider>
                                      {sortOrdersProps => (
                                        <Filters
                                          sortOrdersProps={sortOrdersProps}
                                          onClickRemoveFilters
                                          data={filtersData}
                                          priceLimits={priceLimits}
                                          onChangeOptions={(field, x) => {
                                            setFilter(
                                              field,
                                              x ? [x] : [],
                                              FilterOperator.inSet
                                            );
                                          }}
                                          removeFilter={field => {
                                            removeFilter(field);
                                          }}
                                          onChangePrice={(field, x) => {
                                            setFilter(
                                              "price",
                                              x ? [x.join("-")] : []
                                            );
                                          }}
                                        />
                                      )}
                                    </SortOrdersProvider>
                                  ) : (
                                    <>
                                      <SortOrdersProvider>
                                        {sortOrdersProps => (
                                          <SortDropdown {...sortOrdersProps} />
                                        )}
                                      </SortOrdersProvider>
                                    </>
                                  )}
                                </ProductListHeader>
                                <ProductsList
                                  categoryName={category.name}
                                  products={items}
                                  totalItems={pagination.totalItems}
                                  loading={networkStatus === 3 || loading}
                                  pagination={pagination}
                                  query={query}
                                  fetchMore={fetchMore}
                                />
                                <FooterCategory
                                  category={category}
                                  links={footerLinks}
                                  hideDescription={page > 1}
                                />
                              </>
                            );
                          }}
                        </CategoryProductsListQuery>
                      )}
                    </SearchConsumer>
                  )}
                </>
              );
            }}
          </CategoryHeaderQuery>
        );
      }}
    </MenuQuery>
  );
};

CategoryPage.propTypes = {
  id: PropTypes.string.isRequired
};

export default withProductListingConfig(CategoryPage);
